<template>
    <svg
        class="icon"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <slot />
    </svg>
</template>

<style src="./BoomerIcon.less" lang="less" />
